import React from 'react';
import {Grid, List} from "@mui/material";
import Timeslot from "./Timeslot";

const RoomPart = ({roomPart, roomPartIndex, times}) => {

  return (
    <Grid item xs={12} key={roomPart.id}>
      <List style={{padding: 0}}>
        {Object.keys(times).map((time, index) => (
          <Timeslot
            key={time}
            roomPart={roomPart}
            roomPartIndex={roomPartIndex}
            time={times[time]}
            booking={times[time].booking}
            odd={index % 2 === 0}
          />
        ))}
      </List>
    </Grid>
  );
}

RoomPart.propTypes = {}

export default RoomPart;
