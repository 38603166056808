import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'urql'

import { readMyselfQuery, readPublicConfigQuery } from '../lib/graphql/queries'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

const PublicContext = createContext();

export const usePublicConfig = () => useContext(PublicContext)

const PublicContextProvider = ({ children: reactChildren, addOnsPreset, returnAddOnsPreset }) => {
  // const classes = useStyles();
  const [publicConfig] = useQuery({ query: readPublicConfigQuery, requestPolicy: "cache-and-network" });

  if (publicConfig.fetching || !publicConfig.data) {
    return <Backdrop open={true}>
      <CircularProgress color="inherit"/>
    </Backdrop>
  }

  const contextValue = {
    ...publicConfig.data.readPublicConfig,
    // custheme: custheme,
    // selectedDepartment: selectedDepartment,
    // setSelectedDepartment: setSelectedDepartment,
    // refetchMyself
  }

  return (
    <PublicContext.Provider value={contextValue}>
      {reactChildren}
    </PublicContext.Provider>
  );
}

PublicContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default PublicContextProvider;
