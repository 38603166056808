import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types';
import {Container, CssBaseline} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles'

import LayoutHeader from './components/LayoutHeader'
import LayoutFooter from './components/LayoutFooter'
import LayoutMenu from './components/LayoutMenu'
import {useStyles} from './styles'
import {useMyself} from "../context/MyselfContext";
import theme from '../layout/theme'

const Layout = ({ children, path, noMenu }) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false)

  const custheme = useMemo(() => theme({
    "primary": {
      "main": "#12b015"
    },
    "secondary": {
      "main": "#e00001"
    }
  }), [])

  // close mobile menu after navigation
  useEffect(() => {
    setMenuOpen(false)
  }, [path])

  if (noMenu) {
    return (
      <ThemeProvider theme={custheme}>
        <CssBaseline />
        <LayoutHeader
          classes={classes}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          noMenu={noMenu}
        />
        <LayoutMenu
          classes={classes}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
        />
        <main key={'main'} className={noMenu ? classes.contentPublic : classes.content}>
          <Container>
            {children}
          </Container>
        </main>
        <LayoutFooter />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={custheme}>
      <CssBaseline />
      <LayoutHeader
        classes={classes}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <LayoutMenu
        classes={classes}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        items={[]}
      />
        <main key={'main'} className={classes.content}>
          <Container>
            {children}
          </Container>
        </main>
      <LayoutFooter />
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}


export default Layout;
