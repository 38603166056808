import React from 'react';
// import PropTypes from 'prop-types';
import {useStyles} from './styles';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import Typography from "@mui/material/Typography";
import moment from "moment";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {Skeleton} from "@mui/lab";
import PropTypes from "prop-types";

const BookingDateNavigation = ({handleBack, handleNext, month, year}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.dateNav} justifyContent="space-between">
      <Grid item>
        <Button onClick={handleBack}><KeyboardArrowLeft /></Button>
      </Grid>
      <Grid item>
        <Typography className={classes.title} variant="h5" component={'span'}>
          {month && year
            ? moment(`1-${month}-${year}`, 'D-M-YYYY').format('MMM YYYY')
            : <Skeleton style={{minWidth: '200px'}} />
          }
        </Typography>
      </Grid>
      <Grid item>
        <Button onClick={handleNext}><KeyboardArrowRight /></Button>
      </Grid>
    </Grid>
  );
}

BookingDateNavigation.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
}

export default BookingDateNavigation;
