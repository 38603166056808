import {gql} from "@urql/core";

export const updateMemberMutation = gql`
  mutation UpdateMember($id: ID!, $FirstName: String!, $Surname: String!, $Email: String!, $Active: Boolean, $Departments: [ID], $DepartmentsReadonly: [ID]) {
    updateFrontendMember(id: $id, FirstName: $FirstName, Surname: $Surname, Email: $Email, Active: $Active, Departments: $Departments, DepartmentsReadonly: $DepartmentsReadonly) {
      id
      FirstName
      Surname
      Email
      Active
      Departments {
        id
        Title
      }
      DepartmentsReadonly {
        id
        Title
      }
    }
  }
`;

export const resetPasswordMutation = gql`
  mutation ResetPassword($id: ID!) {
    resetPassword(id: $id)
  }
`;

export const createBookingMutation = gql`
  mutation CreateWeekendBookings($BuildingID: Int!, $RoomIDs: [String]!, $DepartmentID: String!, $Note: String, $Date: String!, $TimeBegin: String!, $TimeEnd: String!, $Events: [EventInput]) {
    createWeekendBookings(BuildingID: $BuildingID, RoomIDs: $RoomIDs, DepartmentID: $DepartmentID, Note: $Note, Date: $Date, TimeBegin: $TimeBegin, TimeEnd: $TimeEnd, Events: $Events) {
      id
    }
  }
`

export const updateWeekendBookingMutation = gql`
  mutation UpdateWeekendBooking($id: ID!, $TimeBegin: String!, $TimeEnd: String!) {
    updateWeekendBooking(input: {id: $id, TimeBegin: $TimeBegin, TimeEnd: $TimeEnd}) {
      id
      TimeBegin
      TimeEnd
    }
  }
`

export const acceptWeekendBookingMutation = gql`
  mutation UpdateWeekendBooking($id: ID!) {
    updateWeekendBooking(input: {id: $id, Requested: false}) {
      id
      Requested
    }
  }
`

export const deleteWeekendBookingMutation = gql`
  mutation DeleteWeekendBookings($ids: [ID]!) {
    deleteWeekendBookings(ids: $ids)
  }
`

export const updateWeekdayBookingMutation = gql`
  mutation UpdateWeekdayBooking($id: ID!, $TimeBegin: String!, $TimeEnd: String!, $RoomPartID: ID!) {
    updateWeekdayBooking(input: {id: $id, TimeBegin: $TimeBegin, TimeEnd: $TimeEnd, RoomPartID: $RoomPartID}) {
      id
      TimeBegin
      TimeEnd
      RoomPartID
    }
  }
`

export const moveWeekdayBookingMutation = gql`
  mutation MoveWeekdayBooking(
    $id: ID!,
    $DepartmentID: String!,
    $TimeBegin: String!,
    $TimeEnd: String!,
    $RoomPartID: ID!
    $Seasons: [ID]!,
    $DateBegin: String!,
    $DateEnd: String
  ) {
    moveWeekdayBooking(
      id: $id,
      DepartmentID: $DepartmentID,
      TimeBegin: $TimeBegin,
      TimeEnd: $TimeEnd,
      RoomPartID: $RoomPartID,
      Seasons: $Seasons,
      DateBegin: $DateBegin,
      DateEnd: $DateEnd
    ) {
      id
      DateBegin
      DateEnd
      TimeBegin
      TimeEnd
      RoomPartID
      Seasons {
        id
      }
      Department {
        id
        Title
      }
    }
  }
`

export const deleteWeekdayBookingMutation = gql`
  mutation DeleteWeekdayBookings($id: ID!) {
    deleteWeekdayBookings(ids: [$id])
  }
`

export const createWeekdayBookingsMutation = gql`
  mutation CreateWeekdayBookings(
    $Day: Int!,
    $RoomPartIDs: [ID]!,
    $DepartmentID: String!,
    $TimeBegin: String!,
    $TimeEnd: String!,
    $Seasons: [ID]!,
    $DateBegin: String!,
    $DateEnd: String
  ) {
    createWeekdayBookings(
      Day: $Day,
      RoomPartIDs: $RoomPartIDs,
      DepartmentID: $DepartmentID,
      TimeBegin: $TimeBegin,
      TimeEnd: $TimeEnd,
      Seasons: $Seasons,
      DateBegin: $DateBegin,
      DateEnd: $DateEnd,
    ) {
      id
      TimeEnd
    }
  }
`

export const updateSeasonMutation = gql`
  mutation UpdateSeason($id: ID!, $title: String, $dayBegin: Int, $monthBegin: Int, $specialBegin: String, $specialEnd: String) {
    updateSeason(input: {id: $id, title: $title, DayBegin: $dayBegin, MonthBegin: $monthBegin, SpecialBegin: $specialBegin, SpecialEnd: $specialEnd}) {
      id
      title
      DayBegin
      MonthBegin
      SpecialBegin
      SpecialEnd
    }
  }
`

export const createSeasonMutation = gql`
  mutation CreateSeason($isSpecialSeason: Boolean!,$title: String!, $dayBegin: Int, $monthBegin: Int, $specialBegin: String, $specialEnd: String) {
    createSeason(input: {IsSpecialSeason: $isSpecialSeason, title: $title, DayBegin: $dayBegin, MonthBegin: $monthBegin, SpecialBegin: $specialBegin, SpecialEnd: $specialEnd}) {
      id
      title
      IsSpecialSeason
      DayBegin
      MonthBegin
      SpecialBegin
      SpecialEnd
    }
  }
`

export const deleteSeasonMutation = gql`
  mutation DeleteSeason($id: ID!) {
    deleteSeasons(ids: [$id])
  }
`

export const createTrainerMutation = gql`
  mutation CreateContactPerson($firstname: String!, $lastname: String!, $phone: String, $email: String, $departmentID: ID!, $PrivacyAccepted: Boolean, $BriefingDone: Boolean) {
    createContactPerson(input: {Firstname: $firstname, Lastname: $lastname, Phone: $phone, Email: $email, DepartmentID: $departmentID, PrivacyAccepted: $PrivacyAccepted, BriefingDone: $BriefingDone}) {
      id
      Firstname
      Lastname
      Phone
      Email
      PrivacyAccepted
      BriefingDone
      Department {
        id
      }
    }
  }
`
export const updateTrainerMutation = gql`
  mutation UpdateContactPerson($input: UpdateContactPersonInput!) {
    updateContactPerson(input: $input) {
      id
      Firstname
      Lastname
      Phone
      Email
      PrivacyAccepted
      BriefingDone
    }
  }
`

export const deleteTrainerMutation = gql`
  mutation DeleteContactPersons($id: ID!) {
    deleteContactPersons(ids: [$id])
  }
`

export const updateTrainingMutation = gql`
  mutation UpdateTraining(
    $ID: ID!,
    $Day: Int!,
    $TimeBegin: String!,
    $TimeEnd: String!,
    $DateBegin: String!,
    $DateEnd: String,
    $Public: Boolean,
    $SignupRequired: Boolean,
    $SoldOut: Boolean,
    $BirthYears: [Int],
    $RoomPartIDs: [ID],
    $SeasonIDs: [ID],
    $ContactPersonID: ID
  ) {
    updateTraining(input: {
      ID: $ID,
      Day: $Day,
      TimeBegin: $TimeBegin,
      TimeEnd: $TimeEnd,
      DateBegin: $DateBegin,
      DateEnd: $DateEnd,
      Public: $Public,
      SignupRequired: $SignupRequired,
      SoldOut: $SoldOut,
      BirthYears: $BirthYears,
      RoomPartIDs: $RoomPartIDs,
      SeasonIDs: $SeasonIDs,
      ContactPersonID: $ContactPersonID
    }) {
      id
      Day
      TimeBegin
      TimeEnd
      DateBegin
      DateEnd
      Public
      SignupRequired
      SoldOut
      BirthYears
      RoomParts {
        id
        title
        Room {
          id
          Building {
            id
            title
          }
        }
      }
      Seasons {
        id
        title
      }
      ContactPerson {
        id
        Firstname
        Lastname
      }
    }
  }
`

export const addTrainingMutation = gql`
  mutation AddTraining(
    $Day: Int!,
    $DepartmentID: ID!,
    $TimeBegin: String!,
    $TimeEnd: String!,
    $DateBegin: String!,
    $DateEnd: String,
    $Public: Boolean,
    $BirthYears: [Int],
    $SignupRequired: Boolean,
    $SoldOut: Boolean,
    $RoomPartIDs: [ID],
    $SeasonIDs: [ID],
    $ContactPersonID: ID
  ) {
    addTraining(input: {
      DepartmentID: $DepartmentID,
      Day: $Day,
      TimeBegin: $TimeBegin,
      TimeEnd: $TimeEnd,
      DateBegin: $DateBegin,
      DateEnd: $DateEnd,
      Public: $Public,
      BirthYears: $BirthYears,
      SignupRequired: $SignupRequired,
      SoldOut: $SoldOut,
      RoomPartIDs: $RoomPartIDs,
      SeasonIDs: $SeasonIDs,
      ContactPersonID: $ContactPersonID
    }) {
      id
      Day
      TimeBegin
      TimeEnd
      DateBegin
      DateEnd
      Public
      SignupRequired
      SoldOut
      BirthYears
      RoomParts {
        id
        title
        Room {
          id
          Building {
            id
            title
          }
        }
      }
      Seasons {
        id
        title
      }
      ContactPerson {
        id
        Firstname
        Lastname
      }
    }
  }
`

export const deleteTrainingScheduleMutation = gql`
  mutation DeleteTrainingSchedules($id: ID!) {
    deleteTrainingSchedules(ids: [$id])
  }
`

export const addEventMutation = gql`
  mutation AddWeekendEvent($input: EventInput!) {
    addWeekendEvent(input: $input) {
      id
      WeekendEvents {
        id
        Title
        TimeBegin
        TimeEnd
        AgeGroup
        Type
        ExpectedAudience
        HasSale
        TypeOfSale
        IsPublic
        HasEntranceFee
        ContactPersonID
      }
    }
  }
`

export const updateEventMutation = gql`
  mutation UpdateWeekendEvent($input: UpdateWeekendEventInput!) {
    updateWeekendEvent(input: $input) {
      id
      Title
      TimeBegin
      TimeEnd
      AgeGroup
      Type
      ExpectedAudience
      HasSale
      TypeOfSale
      IsPublic
      HasEntranceFee
      ContactPersonID
    }
  }
`

export const deleteEventMutation = gql`
  mutation DeleteWeekendEvent($id: ID!) {
    deleteWeekendEvents(ids: [$id])
  }
`
