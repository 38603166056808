import React, {createContext, useContext} from 'react';
import PropTypes from 'prop-types';
import EventContext from "./EventContext";
import {useMutation} from "urql";
import {
  createWeekdayBookingsMutation,
  deleteWeekdayBookingMutation,
  moveWeekdayBookingMutation,
  updateWeekdayBookingMutation
} from "../../../lib/graphql/mutations";
import {useNotificationPush} from "../../Snackbar/SnackbarContext";

const ActionContext = createContext();

export const useAction = () => useContext(ActionContext)

const ActionContextProvider = ({ children: reactChildren, day, roomParts, timeSlot, times, currentSeason, refetchWeekdays }) => {
  const {pushSuccess, pushError} = useNotificationPush()
  const [, executeDeleteBooking] = useMutation(deleteWeekdayBookingMutation)
  const [, executeMoveBooking] = useMutation(moveWeekdayBookingMutation)
  const [, updateBookingTime] = useMutation(updateWeekdayBookingMutation)
  const [, createBookings] = useMutation(createWeekdayBookingsMutation)

  const addBookings = ({day, roomPartIDs, departmentID, timeBegin, timeEnd, seasons, dateBegin, dateEnd}) => {
    return createBookings({
      Day: day,
      RoomPartIDs: roomPartIDs,
      DepartmentID: departmentID,
      TimeBegin: timeBegin,
      TimeEnd: timeEnd,
      Seasons: seasons,
      DateBegin: dateBegin,
      DateEnd: dateEnd,
    }).then(res => {
      if (!res.error) {
        pushSuccess('Hallenzeit angelegt')
        refetchWeekdays()
      } else {
        pushError(res.error.message)
      }
    })
  }

  const editBookingTime = (bookingID, begin, end, roomPartIndex) => {
    updateBookingTime({
      id: bookingID,
      TimeBegin: begin,
      TimeEnd: end,
      RoomPartID: roomParts[roomPartIndex].id
    }).then(res => {
      if (!res.error) {
        pushSuccess('Hallenzeit aktualisiert')
      } else {
        pushError(res.error.message)
      }
    })
  }

  const moveBooking = ({bookingID, begin, end, roomPartID, departmentID, seasons, startDate, endDate}) => {
    console.log('moveBooking', bookingID, begin, end)
    return executeMoveBooking({
      id: bookingID,
      TimeBegin: begin,
      TimeEnd: end,
      RoomPartID: roomPartID,
      DepartmentID: departmentID,
      Seasons: seasons,
      DateBegin: startDate,
      DateEnd: endDate
    }).then(res => {
      if (!res.error) {
        pushSuccess('Hallenzeit aktualisiert')
        refetchWeekdays()
      } else {
        pushError(res.error.message)
      }
    })
  }

  const deleteBooking = ({id}) => {
    return executeDeleteBooking({
      id
    }).then(res => {
      if (!res.error) {
        pushSuccess('Hallenzeit gelöscht')
        refetchWeekdays()
      } else {
        pushError(res.error.message)
      }
    })
  }

  return (
    <EventContext
      day={day}
      roomParts={roomParts}
      timeSlot={timeSlot}
      addBookings={addBookings}
      editBookingTime={editBookingTime}
      moveBooking={moveBooking}
      deleteBooking={deleteBooking}
      times={times}
      currentSeason={currentSeason}
    >
      {reactChildren}
    </EventContext>
  );
}

ActionContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default ActionContextProvider;
