import React from 'react';
import {Route, Router, Switch, useLocation} from "wouter";
import AppNew from "./AppNew";
import {Button} from "@mui/material";
import AppPublic from './AppPublic'

const PublicSwitch = ({}) => {
  const publicUrlSegment = 'public';
  const [, setLocation] = useLocation()

  return (
    <Switch>
      <Route path={`/${publicUrlSegment}/:level1*`}>
        {param => (
          <Router base={`/${publicUrlSegment}`}>
            <AppPublic />
          </Router>
        )}
      </Route>
      <Route>
        <AppNew />
      </Route>
    </Switch>
  );
}

PublicSwitch.propTypes = {}

export default PublicSwitch;
