import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import AddDialog from "../components/AddDialog";
import {useQuery} from "urql";
import {readFutureSeasonsQuery} from "../../../lib/graphql/queries";
import {useMyself, usePermissions} from "../../../context/MyselfContext";
import {WEEKDAY_EDIT_PERMISSION} from "../../../config/constants";
import WeekdayEditDialog from "../../WeekdayEditDialog";

const EventContext = createContext();

export const useEvent = () => useContext(EventContext)

const EventContextProvider = ({ children: reactChildren, day, roomParts, timeSlot, addBookings, editBookingTime, moveBooking, deleteBooking, times, currentSeason }) => {
// console.log(day, roomParts, timeSlot)
  const {selectedDepartment, Departments} = useMyself() || {}
  const { canAccess } = usePermissions() || {}
  const [mode, setMode] = useState('') // edit, add or move
  const [mouseDown, setMouseDown] = useState(false) // edit, add or move

  // edit
  const [booking, setBooking] = useState(null)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const [roomPartIndexes, setRoomPartIndexes] = useState([])
  const [selectedSeasons, setSelectedSeasons] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  // origin
  const [originTime, setOriginTime] = useState(null)
  const [originRoomPartIndex, setOriginRoomPartIndex] = useState(null)

  // hover
  const [currentHoverTime, setCurrentHoverTime] = useState(null)
  const [currentHoverRoomPartIndex, setCurrentHoverRoomPartIndex] = useState(null)

  const [seasonsResult] = useQuery({
    query: readFutureSeasonsQuery,
    pause: !addDialogOpen && !editDialogOpen
  })

  const resetData = () => {
    setMode('')
    setBooking(null)
    setOriginTime(null)
    setOriginRoomPartIndex(null)
    setCurrentHoverTime(null)
    setCurrentHoverRoomPartIndex(null)
    setSelectedSeasons([])
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape" || event.key === "Esc") {
      resetData()
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => document.removeEventListener("keydown", escFunction, false);
  }, [escFunction]);

  useEffect(() => {
    setSelectedSeasons([currentSeason.id])
    const firstRoomIndex = roomPartIndexes[0]
    const room = roomParts[firstRoomIndex]
    setStartDate(room ? moment(room.date, 'YYYY-MM-DD') : null)
  }, [addDialogOpen, currentSeason.id, roomPartIndexes, roomParts])

  useEffect(() => {
    if (booking) {
      setSelectedSeasons(booking.seasons)
      setStartDate(booking.DateBegin ? moment(booking.DateBegin, 'YYYY-MM-DD') : null)
      setEndDate(booking.DateEnd ? moment(booking.DateEnd, 'YYYY-MM-DD') : null)
    }
  }, [editDialogOpen, booking])

  useEffect(() => {
    if (mode === 'add') {
      const minIndex = Math.min(originRoomPartIndex, currentHoverRoomPartIndex)
      const maxIndex = Math.max(originRoomPartIndex, currentHoverRoomPartIndex)

      const list = []
      for (let i = minIndex; i <= maxIndex; i++) {
        list.push(i)
      }

      setRoomPartIndexes(list)
    } else if (mode === 'move') {
      setRoomPartIndexes([originRoomPartIndex])
    } else {
      setRoomPartIndexes([originRoomPartIndex])
    }
  }, [originRoomPartIndex, currentHoverRoomPartIndex, mode])

  const addTimeSlot = useCallback((time, timeSlot) => {
    // console.log('add', timeSlot)
    return moment(time, "HH:mm").add(timeSlot, 'minutes').format('HH:mm')
  }, [])

  // event handler
  const handleMouseDown = (e, booking, roomPartIndex, time, resize = null) => {
    if (!mouseDown) {
      let isRightMB
      if ("which" in e) {
        isRightMB = e.which === 3;   // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
      } else if ("button" in e) { // IE, Opera
        isRightMB = e.button === 2;
      }

      if (isRightMB) {
        return
      }

      resetData()
      if (booking) {
        if (Departments.findIndex(item => item.id === booking.Department.id) < 0) {
          return
        }

        setBooking(booking)
        setMode(resize || 'move')
      } else {
        setMode('add')
      }
      setOriginTime(time)
      setCurrentHoverTime(time)
      setOriginRoomPartIndex(roomPartIndex)
      setCurrentHoverRoomPartIndex(roomPartIndex)
      setMouseDown(true)
    }
  }

  const handleMouseOver = (e, booking, roomPartIndex, time) => {
    // setCurrentTime(time)
    if (mouseDown) {
      setCurrentHoverTime(time)
      if (currentHoverRoomPartIndex !== roomPartIndex) {
        setCurrentHoverRoomPartIndex(roomPartIndex)
      }
    }
  }

  const handleMouseOut = (e, booking, roomPartIndex, time) => {
    // console.log('handleMouseOut', booking,roomPartIndex, time)
  }

  const handleMouseUp = (e, b, roomPartIndex, time) => {
    if (mouseDown) {
      // console.log('handleMouseUp', booking,roomPartIndex, time)
      // console.log(mode === 'move', currentHoverTime, originTime, time === originTime)
      if (mode === 'move' && time === originTime) {
        setMode('edit')
        setEditDialogOpen(true)
        setOriginTime(moment(booking.TimeBegin, 'HH:mm:ss').format('HH:mm'))
        setCurrentHoverTime(moment(booking.TimeEnd, 'HH:mm:ss').format('HH:mm'))
      } else if (['move', 'resizeBegin', 'resizeEnd'].includes(mode)) {
        if (virtualBooking.begin !== virtualBooking.end) {
          editBookingTime(booking.id, virtualBooking.begin, virtualBooking.end, virtualBooking.roomPartIndexes[0])
        }
        resetData()
      } else if (mode === 'add') {
        console.log('add')
        setAddDialogOpen(true)
      }
      setMouseDown(false)
    }
  }

  const handleEditDialogClose = () => {
    setEditDialogOpen(false)
    resetData()
  }

  const handleAddDialogClose = () => {
    setAddDialogOpen(false)
    resetData()
  }

  const virtualBooking = useMemo(() => {
    if (mode) {
      const isOriginMin = moment(originTime, 'HH:mm').isBefore(moment(currentHoverTime || originTime, 'HH:mm'))
      const isOriginMax = moment(originTime, 'HH:mm').isAfter(moment(currentHoverTime || originTime, 'HH:mm'))
      const min = isOriginMin ? originTime : currentHoverTime || originTime
      const max = isOriginMax ? originTime : currentHoverTime || originTime
      let begin, end;

      begin = min
      end = addTimeSlot(max, timeSlot)

      if (mode === 'edit') {
        begin = moment(originTime, 'HH:mm:ss').format('HH:mm')
        end = moment(currentHoverTime, 'HH:mm:ss').format('HH:mm')
      }

      if (mode === 'move') {
        // console.log(booking)
        const diff = moment(currentHoverTime || originTime, 'HH:mm').diff(moment(originTime, 'HH:mm'))
        const diffMinutes = moment.duration(diff).asMinutes()
        begin = moment(booking.TimeBegin, 'HH:mm:ss').add(diffMinutes, 'minutes').format('HH:mm')
        end = moment(booking.TimeEnd, 'HH:mm:ss').add(diffMinutes, 'minutes').format('HH:mm')
      }

      if (mode === 'resizeBegin') {
        // console.log(booking)
        const diff = moment(currentHoverTime || originTime, 'HH:mm').diff(moment(originTime, 'HH:mm'))
        const diffMinutes = moment.duration(diff).asMinutes()
        begin = moment(booking.TimeBegin, 'HH:mm:ss').add(diffMinutes, 'minutes').format('HH:mm')
        end = moment(booking.TimeEnd, 'HH:mm:ss').format('HH:mm')
      }

      if (mode === 'resizeEnd') {
        const diff = moment(currentHoverTime || originTime, 'HH:mm').diff(moment(originTime, 'HH:mm'))
        const diffMinutes = moment.duration(diff).asMinutes()
        begin = moment(booking.TimeBegin, 'HH:mm:ss').format('HH:mm')
        end = moment(booking.TimeEnd, 'HH:mm:ss').add(diffMinutes, 'minutes').format('HH:mm')
      }

      return {
        isOriginMin,
        isOriginMax,
        begin: begin < end ? begin : end,
        end: begin < end ? end : begin,
        roomPartIndexes: roomPartIndexes || [originRoomPartIndex],
        virtual: true,
      }
    }
  }, [originTime, currentHoverTime, roomPartIndexes, originRoomPartIndex, mode, booking, addTimeSlot, timeSlot])

  const handleEditSubmit = () => {
    const firstRoomIndex = roomPartIndexes[0]

    moveBooking({
      bookingID: booking.id,
      roomPartID: roomParts[firstRoomIndex].id,
      departmentID: selectedDepartment,
      begin: virtualBooking?.begin,
      end: virtualBooking?.end,
      seasons: selectedSeasons,
      startDate: startDate,
      endDate: endDate
    }).then(() => {
      setEditDialogOpen(false)
    }).then(resetData)
  }

  const handleDeleteSubmit = () => {
    deleteBooking({id: booking.id}).then(() => {
      setEditDialogOpen(false)
    }).then(resetData)
  }

  const handleAddSubmit = () => {
    const firstRoomIndex = roomPartIndexes[0]
    const date = roomParts[firstRoomIndex]?.date
    const day = moment(date, 'YYYY-MM-DD').format('E')

    addBookings({
      day: parseInt(day, 10),
      roomPartIDs: roomPartIndexes.map(index => roomParts[index].id),
      departmentID: selectedDepartment,
      timeBegin: virtualBooking?.begin,
      timeEnd: virtualBooking?.end,
      seasons: selectedSeasons,
      dateBegin: startDate,
      dateEnd: endDate
    }).then(() => {
      setAddDialogOpen(false)
    }).then(resetData)
  }

  const contextValues = {
    handleMouseOver,
    handleMouseOut,
    handleMouseDown,
    handleMouseUp,
    mode,
    editBooking: booking,
    virtualBooking
  }

  const readonlyContextValues = {
    handleMouseOver: () => {},
    handleMouseOut: () => {},
    handleMouseDown: () => {},
    handleMouseUp: () => {},
    mode: '',
    editBooking: null,
    virtualBooking: null
  }

  return (
    <EventContext.Provider value={canAccess && canAccess(WEEKDAY_EDIT_PERMISSION) ? contextValues : readonlyContextValues}>
      {reactChildren}
      {canAccess && canAccess(WEEKDAY_EDIT_PERMISSION) && (
        <>
          <WeekdayEditDialog
            open={editDialogOpen}
            handleClose={handleEditDialogClose}
            handleSubmit={handleEditSubmit}
            handleDeleteSubmit={handleDeleteSubmit}
            preselectDepartment={booking?.Department.id || ''}
            roomParts={roomParts}
            roomPartIndexes={roomPartIndexes}
            setRoomPartIndexes={setRoomPartIndexes}
            timeBegin={virtualBooking?.begin || ''}
            setTimeBegin={virtualBooking?.isOriginMin ? setOriginTime : setCurrentHoverTime}
            timeEnd={virtualBooking?.end || ''}
            setTimeEnd={virtualBooking?.isOriginMax ? setOriginTime : setCurrentHoverTime}
            times={times}
            timeSlot={timeSlot}
            seasons={seasonsResult.data?.readFutureSeasons || []}
            bookingSeasons={booking?.Seasons || []}
            selectedSeasons={selectedSeasons}
            setSelectedSeasons={setSelectedSeasons}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
          <AddDialog
            open={addDialogOpen}
            handleClose={handleAddDialogClose}
            handleSubmit={handleAddSubmit}
            roomParts={roomParts}
            roomPartIndexes={roomPartIndexes}
            setRoomPartIndexes={setRoomPartIndexes}
            timeBegin={virtualBooking?.begin || ''}
            setTimeBegin={virtualBooking?.isOriginMin ? setOriginTime : setCurrentHoverTime}
            timeEnd={virtualBooking
              ? moment(virtualBooking.end, 'HH:mm').subtract(timeSlot, 'minutes').format('HH:mm')
              : ''
            }
            setTimeEnd={virtualBooking?.isOriginMax ? setOriginTime : setCurrentHoverTime}
            times={times}
            timeSlot={timeSlot}
            seasons={seasonsResult.data?.readFutureSeasons || []}
            selectedSeasons={selectedSeasons}
            setSelectedSeasons={setSelectedSeasons}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </>
      )}
    </EventContext.Provider>
  );
};

EventContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default EventContextProvider;
