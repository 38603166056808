import moment from "moment";

export function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

export function getTimes(startTime, endTime, timeSlot) {
  if (!startTime || !endTime || !timeSlot) {
    return []
  }

  let times = [];
  let time = startTime.clone();

  while (time.isBefore(endTime)) {
      times.push(time.clone());
      time.add(timeSlot, 'minutes')
  }
  return times
}

export function getDaytimesMap(times, roomParts) {
    let days = Array(5).fill(null)
    if (roomParts) {
        days.forEach((day, index) => {
            const map = new Map();
            times.forEach(time => {
                map.set(time.format('HH:mm'), new Array(roomParts.edges.length).fill(false))
            })
            days[index] = map
        })
    }
    return days
}

export function getDayTimesBookingMap(roomParts, days, timeSlot, classes) {
    if (roomParts) {
        roomParts.edges.forEach((roomPart, index) => {
            getDayTimesBookingMapByRoom(days, roomPart.node.WeekdayBookings.edges, index, timeSlot, classes)
        })
    }

    return days
}

export function getDayTimesBookingMapByRoom(days, bookings, roomID, timeSlot, classes) {
    bookings.forEach(booking => {
        let time = moment(booking.TimeBegin || booking.node.TimeBegin, 'HH:mm:ss')
        let first = true
        const endTime = moment(booking.TimeEnd || booking.node.TimeEnd, 'HH:mm:ss')

        while (time.isBefore(endTime)) {
            const day =  booking.Day || booking.node.Day
            let oldMap = days[day - 1].get(time.format('HH:mm'))
            let tmpBooking = JSON.parse(JSON.stringify(booking.node || booking))
            const formatTime = time.format('HH:mm')
            tmpBooking.class = classes.full
            tmpBooking.first = false
            tmpBooking.right = true
            tmpBooking.left = true
            tmpBooking.last = false

            if (first) {
                tmpBooking.title = tmpBooking.Department.Title
                tmpBooking.first = true
                first = false
            }

            if (roomID > 0 && oldMap[roomID - 1] && oldMap[roomID - 1].Department.ID === tmpBooking.Department.ID) {
                oldMap[roomID - 1].right = false
                tmpBooking.left = false
            }

            if (roomID > 0 && oldMap[roomID - 1]) {
                tmpBooking.left = false
            }

            if (time.format('HH:mm:ss') === endTime.format('HH:mm:ss')) {
                tmpBooking.last = true
            }
            time.add(timeSlot, 'minutes')

            oldMap[roomID] = tmpBooking
            days[tmpBooking.Day - 1].set(formatTime, oldMap)
        }
    })

    return days
}
