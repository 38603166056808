import React from 'react';
import PropTypes from 'prop-types';

import WeekNavigation from "./WeekNavigation";
import moment from "moment";
import {useLocation} from 'wouter';

const WeekNavigationProvider = ({ year, week, base = '../../' }) => {
  const [, setLocation] = useLocation();

  const handleBack = () => {
    const curr = moment().year(year).isoWeek(week)
    const next = curr.subtract(1, 'week')
    setLocation(`${base}${next.format('YYYY')}/${next.week()}`);
  }

  const handleNext = () => {
    const curr = moment().year(year).isoWeek(week)
    const next = curr.add(1, 'week')
    setLocation(`${base}${next.format('YYYY')}/${next.week()}`);
  }

  return (
    <WeekNavigation
      year={year}
      week={week}
      handleBack={handleBack}
      handleNext={handleNext}
    />
  );
}

WeekNavigationProvider.propTypes = {
  year: PropTypes.number,
  month: PropTypes.number,
}

export default WeekNavigationProvider;

