import React, {Suspense, useEffect} from 'react';

// import {useRoutes, usePath, navigate} from 'hookrouter';
import {Route, Switch, useLocation, useRoute} from "wouter";
import CircularProgress from '@mui/material/CircularProgress';

import Logout from "./pages/Logout";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import LoginContext from "./features/Login/LoginContext";
import MyselfContext from "./context/MyselfContext";

import LayoutPublic from "./layoutPublic";
import {Provider as URQLProvider} from "urql";
import GraphQlClientPublic from "./lib/graphql/URQLClientSilverstripePublic";
import {WEEKDAY_URL_SEGMENT, WEEKEND_URL_SEGMENT} from "./config/constants";
import PublicContext from './context/PublicContext'
import { RoomView } from './features/RoomView'
import PublicOverview from './features/PublicOverview'

const AcceptedBookings = React.lazy(() => import('./pages/AcceptedBookings'))
const Bookings = React.lazy(() => import('./pages/Bookings'))
const RequestedBookings = React.lazy(() => import('./pages/RequestedBookings'))
const Settings = React.lazy(() => import('./pages/Settings'))
const UserList = React.lazy(() => import('./pages/UserList'))
const Trainer = React.lazy(() => import('./pages/Trainer'))
const Seasons = React.lazy(() => import('./pages/Seasons'))
const Weekday = React.lazy(() => import('./pages/Weekday'))
const TrainingSchedule = React.lazy(() => import('./pages/TrainingSchedule'))
const Export = React.lazy(() => import('./pages/Export'))
const Monitor = React.lazy(() => import('./pages/Monitor'))

const publicRoutes = {
  // '/abo/:method': () => <Suspense fallback={'adfs'}><AboSuccess /></Suspense>,
  '/monitor/': () => <Monitor />,
}

const DashboardRedirect = () => {
  const [, setLocation] = useLocation()

  useEffect(() => {
    setLocation(`/${WEEKEND_URL_SEGMENT}/`)
  }, [])

  return null
}

const AppPublic = () => {
  const [location, setLocation] = useLocation();

  useEffect(() => {
    if (!location.endsWith('/')) {
      setLocation(`${location}/`)
    }
  }, [location])

  return (
    <URQLProvider value={GraphQlClientPublic}>
      <PublicContext>
        <LayoutPublic path={location}>
          <Suspense fallback={<CircularProgress />}>
            <Switch>
              <Route path={`/:roomID/:year/:week/`} >{param => <RoomView {...param} />}</Route>
              <Route path={`/:roomID/:year/`} >{param => <RoomView {...param} />}</Route>
              <Route path={`/:roomID/`} >{param => <RoomView {...param} />}</Route>

              <Route path={'/'}>
                <PublicOverview />
              </Route>
            </Switch>
          </Suspense>
        </LayoutPublic>
      </PublicContext>
    </URQLProvider>
  );
}

AppPublic.propTypes = {}

export default AppPublic;
