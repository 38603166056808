import React from 'react';

import {useStyles} from './styles';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Day from "./components/Day";

const Weekday = ({ times, days, timeSlot, showListView, dayStartTime, dayEndTime, refetchWeekdays, hideListButton = false }) => {
  const classes = useStyles();

  return (
    <Paper>
      <Grid container style={{flexWrap: 'nowrap'}} className={classes.root} spacing={0}>
        <Grid container item xs={4} sm={3} md={2} className={classes.dateNav} justifyContent={"space-around"}>
          {!hideListButton && (
            <Button fullWidth onClick={showListView}>
              Liste anzeigen
            </Button>
          )}
        </Grid>
        <Grid container item xs={8} sm={9} md={10} className={classes.dateNav} justifyContent="space-between">
          <Grid item>
            <Button onClick={() => {
              document.getElementById('test').scrollLeft -= 70
            }}><KeyboardArrowLeft /></Button>
          </Grid>
          <Grid item>
            <Button onClick={() => {
              document.getElementById('test').scrollLeft += 70
            }}><KeyboardArrowRight /></Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container flexWrap={"nowrap"} className={classes.root} spacing={0}>
        <Grid item xs={4} sm={3} md={2}>
          <List disablePadding>
            <ListItem className={classes.listItem} style={{height: '50px'}}>
              <ListItemText primary={'Uhrzeit'}/>
            </ListItem>
            {times.map((time, index) => {
              return <ListItem className={classes.listItem} key={`time-${index}`}>
                <ListItemText secondary={time.format('HH:mm')} />
              </ListItem>
            })}
          </List>
        </Grid>
        <Grid item xs={8} sm={9} md={10} style={{overflowY: 'scroll'}} id={'test'}>
          <Grid container item style={{flexWrap: 'nowrap'}} className={classes.root} spacing={0}>
            {days && days.map(day => (
              <Day
                key={day.id}
                day={day}
                times={times}
                timeSlot={timeSlot}
                refetchWeekdays={refetchWeekdays}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

Weekday.propTypes = {}

export default Weekday;
