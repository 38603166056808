import React, {useMemo} from 'react';
import {useLocation} from 'wouter';
import Weekday from "./Weekday";
import {getTimes} from "../../lib/TimeSlotHelper";
import moment from "moment";
import {useQuery} from "urql";
import { readOnePublicRoomQuery, readOneRoom, readSeasonalWeekdays } from '../../lib/graphql/queries'
import TimeContextProvider from "./context/TimeContext";

const WeekdayProvider = ({ roomID, year, week, isPublic }) => {
  const [, setLocation] = useLocation();

  const showListView = () => {
    setLocation('../../liste/');
  }

  const [roomResult] = useQuery({
    query: isPublic ? readOnePublicRoomQuery : readOneRoom,
    variables: {
      id: roomID
    },
    pause: !roomID
  })

  const roomData = useMemo(() => {
    return roomResult.data?.[isPublic ? 'readOnePublicRoom' : 'readOneRoom']
  }, [isPublic, roomResult.data])

  const startTime = useMemo(() => roomData
    ? moment(roomData.StartTime, 'HH:mm')
    : null
    , [roomData])

  const endTime = useMemo(() => roomData
    ? moment(roomData.EndTime, 'HH:mm')
    : null
    , [roomData])

  const timeSlot = useMemo(() => roomData?.TimeSlot || null, [roomData])

  const [weekdaysResult, refetchWeekdays] = useQuery({
    query: readSeasonalWeekdays,
    variables: {
      RoomID: roomID,
      Year: year,
      Week: week,
    },
    pause: !roomID || !year || !week,
    requestPolicy: "cache-and-network"
  })

  const times = getTimes(startTime, endTime, timeSlot)

  return (
    <TimeContextProvider>
      <Weekday
        times={times}
        timeSlot={timeSlot}
        days={weekdaysResult.data?.readWeekdays.Weekdays || []}
        showListView={showListView}
        dayStartTime={startTime}
        dayEndTime={endTime}
        refetchWeekdays={refetchWeekdays}
        hideListButton={isPublic}
      />
    </TimeContextProvider>
  );
}

WeekdayProvider.propTypes = {}

export default WeekdayProvider;
