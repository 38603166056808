import React, {useMemo} from 'react';

import {useStyles} from '../styles';
import {Grid, List, ListItem, ListItemText} from "@mui/material";
import RoomPart from "./RoomPart";
import moment from "moment";
import ActionContextProvider from "../context/ActionContext";

const Day = ({day, times, timeSlot, refetchWeekdays}) => {
  const classes = useStyles();

  const roomPartMap = useMemo(() => {
    const map = []

    day.RoomParts.forEach((roomPart, roomPartIndex) => {
      map.push({
        times: times.reduce((acc, item) => {
          return {...acc, [item.format('HH:mm')]: {
            booking: null,
            time: item
          }}
        }, {})
      })

      roomPart.SeasonalWeekdayBookings.forEach(booking => {
        let time = moment(booking.TimeBegin, 'HH:mm:ss')
        const endTime = moment(booking.TimeEnd, 'HH:mm:ss')
        let first = true

        // console.log(booking)
        const trainings = roomPart.SeasonalTrainingSchedules.filter(item => {
          return booking.Department.id === item.Department.id && (
            moment(item.TimeBegin, 'HH:mm:ss').isBetween(time, endTime)
              || moment(item.TimeEnd, 'HH:mm:ss').isBetween(time, endTime)
          )
        })

        let firstOfTrainings = trainings.map(item => item.id)

        while (time.isBefore(endTime)) {
          let tmpBooking = {...booking}
          tmpBooking.class = classes.full
          tmpBooking.first = false
          tmpBooking.right = true
          tmpBooking.left = true
          tmpBooking.last = false
          tmpBooking.seasons = booking.Seasons.reduce((acc, item) => [...acc, item.id], [])

          if (first) {
            tmpBooking.title = tmpBooking.Department.Title
            tmpBooking.first = true
            first = false
          }

          const currentTraining = trainings.find(item => {
            const trainingStart = moment(item.TimeBegin, 'HH:mm:ss')
            const trainingEnd = moment(item.TimeEnd, 'HH:mm:ss')
            return time.isBetween(trainingStart, trainingEnd)
          })

          if (currentTraining) {
            tmpBooking.training = {...currentTraining}
            if (firstOfTrainings.includes(currentTraining.id)) {
              tmpBooking.training.first = true
              tmpBooking.title = tmpBooking.Department.Title
              firstOfTrainings = firstOfTrainings.filter(item => item !== currentTraining.id)
            }
          }

          if (roomPartIndex > 0 && map[roomPartIndex - 1].booking && map[roomPartIndex - 1].booking.Department.ID === tmpBooking.Department.ID) {
            map[roomPartIndex - 1].booking.right = false
            tmpBooking.left = false
          }

          if (roomPartIndex > 0 && map[roomPartIndex - 1].booking) {
            tmpBooking.left = false
          }

          const nextTime = time.clone()
          const nextTimeAdded = nextTime.add(timeSlot, 'minutes').format('HH:mm:ss')
          if (nextTimeAdded === endTime.format('HH:mm:ss')) {
            tmpBooking.last = true
          }

          if (tmpBooking.training && nextTimeAdded === moment(tmpBooking.training.TimeEnd, 'HH:mm:ss').format('HH:mm:ss')) {
            tmpBooking.training.last = true
          }

          map[roomPartIndex].times[time.format('HH:mm')].booking = tmpBooking

          time.add(timeSlot, 'minutes')
        }
      })
    })

    return map
  }, [day, times, timeSlot, classes])

  return (
    <Grid item className={classes.grid}>
      <List disablePadding>
        <ListItem className={classes.listItem} style={{height: '50px'}}>
          <ListItemText primary={day.Title} secondary={day.Season.title} />
        </ListItem>
      </List>

      <Grid container item xs={12} spacing={0} flexWrap={"nowrap"}>
        <ActionContextProvider
          day={roomPartMap}
          roomParts={day.RoomParts}
          times={times}
          timeSlot={timeSlot}
          currentSeason={day.Season}
          refetchWeekdays={refetchWeekdays}
        >
          {day.RoomParts.map((roomPart, roomPartIndex) => (
            <RoomPart
              key={roomPart.id}
              roomPart={roomPart}
              roomPartIndex={roomPartIndex}
              times={roomPartMap[roomPartIndex].times}
            />
          ))}
        </ActionContextProvider>
      </Grid>
    </Grid>
  );
}

Day.propTypes = {}

export default Day;
