import {makeStyles} from '@mui/styles';
import green from "@mui/material/colors/green";
import red from "@mui/material/colors/red";
import {DRAWER_WIDTH} from "../../config/config";

/**
 * Material UI Styles for Layout feature
 */
export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  tableCell: {
    '&:hover': {
      background: 'red'
    }
  },
  dateNav: {
    margin: '15px 0 10px'
  },
  grid: {
    flexGrow: 1,
    // minWidth: '150px',
    flexWrap: 'nowrap',
    borderRight: `1px solid transparent`
  },
  gridSub: {
    flexGrow: 1,
    flexWrap: 'nowrap'
  },
  listItem: {
    boxSizing: 'border-box',
    height: '25px',
    textAlign: 'center',
    borderRight: `1px solid ${theme.palette.action.selected}`,
    borderBottom: `1px solid ${theme.palette.action.selected}`,
  },
  listItemSub: {
    boxSizing: 'border-box',
    width: '100%',
    minWidth: '100px',
    height: '25px',
    textAlign: 'center',
    // borderRight: `1px solid ${grey["200"]}`,
  },
  available: {
    background: green["400"],
    borderRight: `1px solid ${green["500"]}`,
    borderBottom: `1px solid ${green["500"]}`,
    '&:hover': {
      background: green['500'],
      cursor: 'pointer'
    }
  },
  full: {
    background: red["400"],
    '&:hover': {
      background: red['500'],
      cursor: 'pointer'
    }
  },
  topBorder: {
    borderTop: `1px solid ${red["700"]}`,
  },
  rightBorder: {
    borderRight: `1px solid ${red["700"]}`,
  },
  bottomBorder: {
    borderBottom: `1px solid ${red["700"]}`,
  },
  leftBorder: {
    borderLeft: `1px solid ${red["700"]}`,
  },
  primary: {
    fontSize: '12px'
  },
  secondary: {
    fontSize: '12px'
  },
  multiline: {
    marginTop: '0',
    marginBottom: '0',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  bottomDialog: {
    [theme.breakpoints.up('md')]: {
      marginLeft: DRAWER_WIDTH
    }
  },
  formControl: {
    margin: '10px 0'
  }
}));
