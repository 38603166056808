import React from 'react';
// import PropTypes from 'prop-types';
import {useStyles} from './styles';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import Typography from "@mui/material/Typography";
import moment from "moment";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import PropTypes from "prop-types";

const WeekNavigation = ({handleBack, handleNext, year, week}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.dateNav} justifyContent="space-between">
      <Grid item>
        <Button onClick={handleBack}><KeyboardArrowLeft /></Button>
      </Grid>
      <Grid item style={{textAlign: 'center'}}>
        <Typography className={classes.title} variant="h5" component={'span'}>
          Kalenderwoche {week}
        </Typography>
        <Typography className={classes.title} variant="body2" component={'p'}>
          {moment().year(year).isoWeek(week).isoWeekday(1).format('DD.MM.YYYY')}
          {' - '}
          {moment().year(year).isoWeek(week).isoWeekday(5).format('DD.MM.YYYY')}
        </Typography>
      </Grid>
      <Grid item>
        <Button onClick={handleNext}><KeyboardArrowRight /></Button>
      </Grid>
    </Grid>
  );
}

WeekNavigation.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
  week: PropTypes.number.isRequired,
}

export default WeekNavigation;
