import {gql} from '@urql/core';

export const roomQuery = gql`
  query ReadSiteBuildings {
    readSiteBuildings {
      id
      Title
      Rooms {
        edges {
          node {
            id
            Title
            RoomParts {
              pageInfo {
               totalCount
              }
            }
          }
        }
      }
    }
  }
`

export const menuItemFragment = gql`
  fragment menuItem on MenuItem {
    Title
    Description
    Link
    Icon
    SmallSubItems
    Badge
  }
`

export const readMyselfQuery = gql`
  query {
    readMyself {
      id
      FirstName
      Surname
      Email
      IsAdmin
      Permissions
      Departments {
        id
        Title
      }
      DepartmentsReadonly {
        id
        Title
      }
      AppMenuItems {
        Title
        Description
        Link
        Icon
        Badge
        SmallSubItems
        SubItems {
          ...menuItem
          SubItems {
            ...menuItem
          }
        }
      }
      ThemePalette
      SecurityToken
      BarrierFreeMode
      NotificationAccept
      NotificationUpdate
      NotificationRequest
      NotificationDelete
    }
  }
  ${menuItemFragment}
`

export const readBuildings = gql`
  query ReadBuildings($startDate: String!, $endDate: String) {
    readBuildings {
      id
      title
      startTime
      endTime
      timeSlot
      rooms {
        id
        title
        weekendBookings(filter: {Date: {gte: $startDate, lte: $endDate}}) {
          id
          Date
          TimeBegin
          TimeEnd
          Department {
            id
            Title
          }
        }
      }
    }
  }
`

const weekendBookingGroupFragment = gql`
  fragment weekendBookingGroupDetails on WeekendBookingGroup {
    id
    Date
    Note
    Building {
      id
      title
      timeSlot
    }
    WeekendEvents {
      id
      Title
      TimeBegin
      TimeEnd
      AgeGroup
      Type
      ExpectedAudience
      HasSale
      TypeOfSale
      IsPublic
      HasEntranceFee
      ContactPersonID
    }
    WeekendBookings {
      id
      TimeBegin
      TimeEnd
      Requested
      room {
        id
        Title
      }
    }
    Department {
      id
      Title
    }
  }
`

export const readRequestedWeekendBookingGroups = gql`
  query ReadWeekendBookingGroups($startDate: String!) {
    readWeekendBookingGroups(filter: {WeekendBookings: {Requested: {eq: true}}, Date: {gte: $startDate}}) {
      ...weekendBookingGroupDetails
    }
  }
  ${weekendBookingGroupFragment}
`

export const readWeekendBookingGroups = gql`
  query ReadWeekendBookingGroups($startDate: String!, $endDate: String) {
    readWeekendBookingGroups(filter: {Date: {gte: $startDate, lte: $endDate}}) {
      ...weekendBookingGroupDetails
    }
  }
  ${weekendBookingGroupFragment}
`

export const readFilteredWeekendBookingGroups = gql`
  query ReadWeekendBookingGroups($departmentID: ID, $startDate: String!, $endDate: String) {
    readWeekendBookingGroups(filter: {DepartmentID: {eq: $departmentID}, Date: {gte: $startDate, lte: $endDate}}) {
      ...weekendBookingGroupDetails
    }
  }
  ${weekendBookingGroupFragment}
`

export const readMembersQuery = gql`
  query ReadMembers {
    readMembers {
      id
      FirstName
      Surname
      Email
      Active
      Departments {
        id
        Title
      }
      DepartmentsReadonly {
        id
        Title
      }
    }
  }
`

export const readOneMemberQuery = gql`
  query ReadOneMember($ID: ID!) {
    readOneMember(filter: {id: {eq: $ID}}) {
      id
      FirstName
      Surname
      Email
      Active
      Departments {
        id
        Title
      }
      DepartmentsReadonly {
        id
        Title
      }
    }
    readDepartments {
      id
      Title
    }
  }
`;

export const readSeasonalWeekdays = gql`
  query ReadWeekdays($RoomID: ID!, $Year: Int!, $Week: Int!){
    readWeekdays(RoomID: $RoomID, year: $Year, week: $Week) {
      id
      Room {
        Title
      }
      Weekdays {
        id
        Title
        Date
        Season {
          id
          title
        }
        RoomParts {
          id
          title
          date
          SeasonalTrainingSchedules {
            id
            Day
            DateBegin
            DateEnd
            TimeBegin
            TimeEnd
            Department {
                id
            }
            RoomParts {
                id
            }
          }
          SeasonalWeekdayBookings {
            id
            Day
            DateBegin
            DateEnd
            TimeBegin
            TimeEnd
            RoomPartID
            Seasons {
              id
              title
            }
            Department {
              id
              Title
            }
          }
        }
      }
    }
  }
`

export const weekdayListFragment = gql`
  fragment weekdayList on WeekdayBooking {
    id
    Day
    Department {
      id
      Title
    }
    RoomPart {
      id
      title
    }
    TimeBegin
    TimeEnd
    DateBegin
    DateEnd
    Seasons {
      id
      title
    }
  }
`

export const readWeekdayList = gql`
  query ReadWeekdayBookings($roomID: ID!, $seasonID: ID) {
    readWeekdayBookings(filter: {RoomPart: {RoomID: {eq: $roomID}}, Seasons: {id: {eq: $seasonID}}}) {
      ...weekdayList
    }
  }
  ${weekdayListFragment}
`

export const readAllWeekdayList = gql`
  query ReadAllWeekdayBookings($roomID: ID!) {
    readWeekdayBookings(filter: {RoomPart: {RoomID: {eq: $roomID}}}) {
      ...weekdayList
    }
  }
  ${weekdayListFragment}
`

export const readFutureSeasonsQuery = gql`
  {
    readFutureSeasons {
      id
      title
    }
  }
`

export const readOneRoom = gql`
  query ReadOneRoom($id: ID!) {
    readOneRoom(filter: {id: {eq: $id}})  {
      id
      TimeSlot
      StartTime
      EndTime
      roomParts {
        id
        title
      }
    }
  }
`

export const readOneSeasonQuery = gql`
  query ReadOneSeason($id: ID!) {
    readOneSeason(filter: {id: {eq: $id}}) {
      id
      title
      DayBegin
      MonthBegin
      IsSpecialSeason
      SpecialBegin
      SpecialEnd
    }
  }
`

export const readSeasons = gql`
  {
    readSeasons(filter: {IsSpecialSeason: {eq: false}}) {
      id
      title
      DayBegin
      MonthBegin
    }
  }
`

export const readSpecialSeasons = gql`
  query ReadSeasons($SpecialEnd: String) {
    readSeasons(filter: {IsSpecialSeason: {eq: true}, SpecialEnd: {lte: $SpecialEnd}}) {
      id
      title
      IsSpecialSeason
      SpecialBegin
      SpecialEnd
    }
  }
`

export const readTrainersQuery = gql`
  query ReadContactPersons($departmentID: ID!) {
    readContactPersons(filter: {DepartmentID: {eq: $departmentID}}) {
      id
      Firstname
      Lastname
      Phone
      Email
      PrivacyAccepted
      BriefingDone
    }
  }
`

export const readEventContactPersonsQuery = gql`
  query ReadEventContactPersons($departmentID: ID!) {
#    readContactPersons(filter: {DepartmentID: {eq: $departmentID}, BriefingDone: {eq: true}}) {
    readContactPersons(filter: {DepartmentID: {eq: $departmentID}}) {
      id
      Firstname
      Lastname
      Phone
      Email
      PrivacyAccepted
      BriefingDone
    }
  }
`

export const readOneTrainerQuery = gql`
  query ReadOneContactPerson($id: ID!) {
    readOneContactPerson(filter: {id: {eq: $id}}) {
      id
      Firstname
      Lastname
      Phone
      Email
      PrivacyAccepted
      BriefingDone
    }
  }
`

export const trainingScheduleFragment = gql`
  fragment trainingSchedule on TrainingSchedule {
    id
    Day
    TimeBegin
    TimeEnd
    DateBegin
    DateEnd
    Public
    SignupRequired
    SoldOut
    BirthYears
    RoomParts {
      id
      title
      Room {
        id
        Building {
          id
          title
        }
      }
    }
    Seasons {
      id
      title
    }
    ContactPerson {
      id
      Firstname
      Lastname
    }
  }
`

export const readTrainingSchedules = gql`
  query ReadTrainingSchedules($departmentID: ID) {
    readTrainingSchedules(filter: {Department: {id: {eq: $departmentID}}}) {
      ...trainingSchedule
    }
    readContactPersons(filter: {DepartmentID: {eq: $departmentID}}) {
      id
      Firstname
      Lastname
    }
    readFutureSeasons {
      id
      title
    }
  }
  ${trainingScheduleFragment}
`

export const readOneTrainingSchedule = gql`
  query ReadOneTrainingSchedule($id: ID!) {
    readOneTrainingSchedule(filter: {id: {eq: $id}}) {
      ...trainingSchedule
    }
    readBuildings {
      id
      title
      rooms {
        id
        title
        TimeSlot
        StartTime
        EndTime
        roomParts {
          id
          title
        }
      }
    }
  }
  ${trainingScheduleFragment}
`

export const readTrainingScheduleBuildings = gql`
  {
    readBuildings {
      id
      title
      rooms {
        id
        title
        TimeSlot
        StartTime
        EndTime
        roomParts {
          id
          title
        }
      }
    }
  }
`

export const readPublicBuildings = gql`
  {
    readPublicBuildings {
      id
      title
      publicRooms {
        id
        title
      }
    }
  }
`

export const readPublicConfigQuery = gql`
  {
    readPublicConfig {
      rooms {
        id
        title
        Building {
          id
          title
        }
      }
    }
  }
`

export const readOnePublicRoomQuery = gql`
  query ReadOnePublicRoom($id: ID!) {
    readOnePublicRoom(id: $id) {
      id
      title
      StartTime
      EndTime
      TimeSlot
      Building {
        id
        title
      }
      roomParts {
        id
        title
      }
    }
  }
`
