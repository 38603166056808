import React, {useMemo} from 'react';

import {useStyles} from '../stylesItem';
import {ListItem, ListItemText, Tooltip} from "@mui/material";
import {useEvent} from "../context/EventContext";
import {useMyself, usePermissions} from "../../../context/MyselfContext";
import {WEEKDAY_EDIT_PERMISSION} from "../../../config/constants";
import moment from "moment";

const Timeslot = ({time, roomPartIndex, booking, odd}) => {
  const classes = useStyles();
  const {canAccess} = usePermissions() || {}
  const {BarrierFreeMode} = useMyself() || {}
  const {
    handleMouseOver,
    handleMouseDown,
    handleMouseUp,
    mode,
    editBooking,
    virtualBooking
  } = useEvent()

  const currentBooking = useMemo(() => {
    if (virtualBooking) {
      if (virtualBooking.roomPartIndexes.includes(roomPartIndex)
        && virtualBooking.begin <= time.time.format('HH:mm')
        && time.time.format('HH:mm') < virtualBooking.end
      ) {
        return virtualBooking
      }
    }

    if (mode !== 'edit' && editBooking && booking && editBooking.id === booking.id) {
      return null
    }

    return booking
  }, [editBooking, booking, virtualBooking, mode, roomPartIndex, time.time]);

  const timeString = useMemo(() => {
    if (!booking) {
      return null
    }

    if (booking.training) {
      return 'Training: ' + moment(booking.training.TimeBegin, 'HH:mm:ss').format('HH:mm')
        + ' - ' + moment(booking.training.TimeEnd, 'HH:mm:ss').format('HH:mm')
    }

    return moment(booking.TimeBegin, 'HH:mm:ss').format('HH:mm')
      + ' - ' + moment(booking.TimeEnd, 'HH:mm:ss').format('HH:mm')
  }, [booking])

  const availableClass = BarrierFreeMode ? classes.availableContrast : classes.available
  const availableOddClass = BarrierFreeMode ? classes.availableOddContrast : classes.availableOdd

  const returnValue = (
    <ListItem style={{padding: '0'}} className={`${classes.gridSub}`}>
      <span
        className={[
          classes.listItemSub,
          currentBooking ? (currentBooking.virtual ? classes.virtual : classes.full) : classes.available,
          odd ? (
            currentBooking ? (currentBooking.virtualOdd ? classes.virtualOdd : classes.fullOdd) : availableOddClass
          ) : (
            currentBooking ? (currentBooking.virtual ? classes.virtual : classes.full) : availableClass
          ),
          // props.currentHoverID && currentBooking && props.currentHoverID === currentBooking.ID ? classes.hover : null,
          // props.createRoomPartID === props.roomPartID && props.createBeginTime <= props.time && props.time <= props.currentHoverTime ? classes.hoverFree : null,
          currentBooking?.first || currentBooking?.training?.first ? classes.topBorder : null,
          currentBooking?.right ? classes.rightBorder : null,
          currentBooking?.last || currentBooking?.training?.last ? classes.bottomBorder : null,
          currentBooking?.left ? classes.leftBorder : null,
          currentBooking?.virtual ? classes.virtual : null,
          !canAccess || !canAccess(WEEKDAY_EDIT_PERMISSION) ? classes.nopointer : null,
          currentBooking?.training ? classes.fullTraining : null
        ].join(' ')}
        // onClick={e => handleMouseDown(e, currentBooking, roomPartIndex, time.time.format('HH:mm'))}
        onMouseOver={e => handleMouseOver(e, currentBooking, roomPartIndex, time.time.format('HH:mm'))}
        onMouseDown={e => handleMouseDown(e, currentBooking, roomPartIndex, time.time.format('HH:mm'), e.target.getAttribute('data-resize'))}
        onMouseUp={e => handleMouseUp(e, currentBooking, roomPartIndex, time.time.format('HH:mm'))}
        // style={currentBooking?.virtual ? ({background: grey[200]}) : null}
      >

        <ListItemText
          classes={{
            primary: classes.primary,
            secondary: classes.secondary,
            multiline: classes.multiline,
            root: classes.listTextRoot
          }}
          // primary={props.currentHoverID && currentBooking && props.currentHoverID === currentBooking.ID ? 'hover' : ''}
          secondary={currentBooking?.title
            ? (
              <span className={classes.title}>{currentBooking?.title ? currentBooking.title : ''}</span>
            ) : null
          }
        />
        {currentBooking?.first  && (
          <span
            className={classes.dndFirst}
            data-resize={'resizeBegin'}
          ></span>
        )}
        {currentBooking?.last  && (
          <span
            className={classes.dndLast}
            data-resize={'resizeEnd'}
          ></span>
        )}
      </span>
      {/*{formDialog}*/}
    </ListItem>
  )

  return timeString ? (
    <Tooltip title={timeString} placement="top">
      {returnValue}
    </Tooltip>
  ) : returnValue
}

Timeslot.propTypes = {}

export default Timeslot;
