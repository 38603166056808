import React from 'react';

import WeekdayEditDialog from "./WeekdayEditDialog";

const WeekdayEditProvider = ({
                               open,
                               handleClose,
                               handleSubmit,
                               handleDeleteSubmit,
                               preselectDepartment,
                               roomParts,
                               roomPartIndexes,
                               setRoomPartIndexes,
                               timeBegin,
                               setTimeBegin,
                               timeEnd,
                               setTimeEnd,
                               times,
                               timeSlot,
                               seasons,
                               bookingSeasons,
                               selectedSeasons,
                               setSelectedSeasons,
                               startDate,
                               setStartDate,
                               endDate,
                               setEndDate
                             }) => {
  return (
    <WeekdayEditDialog
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      handleDeleteSubmit={handleDeleteSubmit}
      preselectDepartment={preselectDepartment}
      roomParts={roomParts}
      roomPartIndexes={roomPartIndexes}
      setRoomPartIndexes={setRoomPartIndexes}
      timeBegin={timeBegin}
      setTimeBegin={setTimeBegin}
      timeEnd={timeEnd}
      setTimeEnd={setTimeEnd}
      times={times}
      timeSlot={timeSlot}
      seasons={seasons}
      bookingSeasons={bookingSeasons}
      selectedSeasons={selectedSeasons}
      setSelectedSeasons={setSelectedSeasons}
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
    />
  );
}

WeekdayEditProvider.propTypes = {}

export default WeekdayEditProvider;
