import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import AppNew from './AppNew';
import PublicSwitch from './PublicSwitch';

import HttpsRedirect from "react-https-redirect";
import Snackbar from "./features/Snackbar";

import 'moment/locale/de'
import moment from "moment";
import {ThemeProvider} from "@mui/styles";
import {createTheme} from "@mui/material";

moment.locale('de')

// Bugsnag.start({
//   apiKey: 'd946ebcea8f73e7d21cedc60e96a6bfc',
//   plugins: [new BugsnagPluginReact()],
//   appType: 'dashboard',
//   generateAnonymousId: true,
//   releaseStage: process.env.NODE_ENV
// })
//
// const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);
//
// ReactDOM.render(
//   <ErrorBoundary>
//     <HttpsRedirect>
//       <ThemeProvider theme={createTheme()} >
//         <Snackbar>
//           <AppNew />
//           <PublicSwitc />
//         </Snackbar>
//       </ThemeProvider>
//     </HttpsRedirect>
//   </ErrorBoundary>
//   , document.getElementById('root')
// )

ReactDOM.render(
    <HttpsRedirect>
      <ThemeProvider theme={createTheme()}>
        <Snackbar>
          {/*<AppNew />*/}
          <PublicSwitch />
        </Snackbar>
      </ThemeProvider>
    </HttpsRedirect>
  , document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
