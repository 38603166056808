import { useQuery } from 'urql'
import { readOnePublicRoomQuery } from '../../lib/graphql/queries'
import React, { useEffect, useMemo } from 'react'
import { WEEKDAY_URL_SEGMENT, WEEKEND_URL_SEGMENT } from '../../config/constants'
import moment from 'moment/moment'
import { useLocation } from 'wouter'
import BookingDateNavigation from '../BookingDateNavigation'
import WeekNavigation from '../WeekNavigation'
import { getTimes } from '../../lib/TimeSlotHelper'
import Weekday from '../Weekday'
import Typography from '@mui/material/Typography'

export const RoomView = ({year, week, roomID}) => {
  const [, setLocation] = useLocation();
  const [readOnePublicRoomResult] = useQuery({
    query: readOnePublicRoomQuery,
    variables: {
      id: roomID
    },
    pause: !roomID
  })


  useEffect(() => {
    if (!roomID) {
      return
    }

    if (!year || !parseInt(year, 10) || parseInt(year, 10).toString() !== year) {
      setLocation(`/${roomID}/${moment().format('YYYY')}/${moment().week()}/`)
    } else if (!parseInt(week, 10) || parseInt(week, 10).toString() !== week) {
      setLocation(`/${roomID}/${parseInt(year, 10)}/${moment().week()}`)
    } else if (week && parseInt(week, 10).toString() !== week) {
      setLocation(`/${roomID}/${parseInt(year, 10)}/${parseInt(week, 10)}/`)
    }
  }, [year, week, roomID, setLocation])


  const startTime = useMemo(() => readOnePublicRoomResult.data
      ? moment(readOnePublicRoomResult.data.readOnePublicRoom.StartTime, 'HH:mm')
      : null
    , [readOnePublicRoomResult])

  const endTime = useMemo(() => readOnePublicRoomResult.data
      ? moment(readOnePublicRoomResult.data.readOnePublicRoom.EndTime, 'HH:mm')
      : null
    , [readOnePublicRoomResult])

  const timeSlot = useMemo(() => readOnePublicRoomResult.data?.readOnePublicRoom.TimeSlot || null, [readOnePublicRoomResult])

  const times = getTimes(startTime, endTime, timeSlot)

  console.log(times)

  if (!year || !week) {
    return null;
  }

  return (
    <>
      <Typography variant={'h3'} component={'h1'} sx={{textAlign: 'center'}}>
        {readOnePublicRoomResult.data?.readOnePublicRoom?.title} - {readOnePublicRoomResult.data?.readOnePublicRoom?.Building?.title}
      </Typography>
      <WeekNavigation base={`/${roomID}/`} year={parseInt(year, 10)} week={parseInt(week, 10)} />

      <Weekday
        roomID={roomID}
        year={parseInt(year, 10)}
        week={parseInt(week, 10)}
        isPublic

      />
    </>
  )
}
