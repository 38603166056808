import {makeStyles} from '@mui/styles';

/**
 * Material UI Styles for Layout feature
 */
export const useStyles = makeStyles(theme => ({
  dateNav: {
    margin: '15px 0 10px'
  },
}));
