import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import {useLocation} from 'wouter'

const LayoutHeader = ({ classes, menuOpen, setMenuOpen, noMenu }) => {
  const [, setLocation] = useLocation()
  return (
    <AppBar position="fixed" className={!noMenu && classes.appBar}>
      <Toolbar variant="dense" className={classes.toolbar}>
        <IconButton onClick={() => setMenuOpen(!menuOpen)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" color="inherit" className={classes.title}>
          {/*Title*/}
        </Typography>
        {!noMenu && (
          <Button
            color="inherit"
            onClick={() => setLocation('/logout/')}
          >
            Abmelden
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}

LayoutHeader.propTypes = {
  classes: PropTypes.object
}

export default LayoutHeader;
