import React from 'react';

import {useStyles} from '../styles';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

import {useMyself} from "../../../context/MyselfContext";
import moment from 'moment'

const AddDialog = ({
                     open,
                     handleClose,
                     handleSubmit,
                     roomParts,
                     roomPartIndexes,
                     setRoomPartIndexes,
                     timeBegin,
                     setTimeBegin,
                     timeEnd,
                     setTimeEnd,
                     times,
                     timeSlot,
                     seasons,
                     selectedSeasons,
                     setSelectedSeasons,
                     startDate,
                     setStartDate,
                     endDate,
                     setEndDate
                   }) => {
  const classes = useStyles();

  const {selectedDepartment, setSelectedDepartment, Departments} = useMyself() || {}

  const handleRoomPartChange = (checked, roomPartId) => {
    const roomPartIndex = roomParts.findIndex(item => item.id === roomPartId)

    if (checked) {
      setRoomPartIndexes(prev => [...prev, roomPartIndex])
    } else {
      setRoomPartIndexes(prev => prev.filter(item => item !== roomPartIndex))
    }
  }

  const handleSeasonChange = (checked, seasonId) => {
    if (checked) {
      setSelectedSeasons(prev => [...prev, seasonId])
    } else {
      setSelectedSeasons(prev => prev.filter(item => item !== seasonId))
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
      <DialogTitle>Hallenzeit hinzufügen</DialogTitle>
      <DialogContent>

        <FormControl fullWidth variant={"standard"} className={classes.formControl}>
          <InputLabel id="department-label">Abteilung</InputLabel>
          <Select
            labelId="department-label"
            id="department"
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
          >
            {Departments && Departments.map(department => (
              <MenuItem value={department.id} key={department.id}>
                {department.Title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth component="fieldset" variant="standard" className={classes.formControl}>
          <FormLabel component="legend">Assign responsibility</FormLabel>
          <FormGroup>
            {roomParts.map((roomPart, index) => (
              <FormControlLabel
                key={roomPart.id}
                control={
                  <Checkbox
                    onChange={e => handleRoomPartChange(e.target.checked, e.target.name)}
                    checked={roomPartIndexes.includes(index)}
                    name={roomPart.id}
                    sx={{py: 0.5}}
                  />
                }
                label={roomPart.title}
              />
            ))}
          </FormGroup>
        </FormControl>

        <FormControl fullWidth variant={"standard"} className={classes.formControl}>
          <InputLabel id="end-label">Beginn</InputLabel>
          <Select
            labelId="begin-label"
            id="begin"
            value={timeBegin}
            onChange={(e) => setTimeBegin(e.target.value)}
          >
            {times && times.map((time, timeIndex) => {
              return (
                <MenuItem value={time.format('HH:mm')} key={`time${timeIndex}`}>
                  {time.format('HH:mm')} Uhr
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth variant={"standard"} className={classes.formControl}>
          <InputLabel id="end-label">Ende</InputLabel>
          <Select
            labelId="end-label"
            id="end"
            value={timeEnd}
            onChange={(e) => setTimeEnd(e.target.value)}
          >
            {times && times.map((time, timeIndex) => {
              const tmpTime = moment(time.format('HH:mm'), 'HH:mm').add(timeSlot, 'minutes')
              return (
                <MenuItem value={time.format('HH:mm')} key={`time${timeIndex}`}>
                  {tmpTime.format('HH:mm')} Uhr
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth component="fieldset" variant="standard" className={classes.formControl}>
          <FormLabel component="legend">Saisons</FormLabel>
          <FormGroup>
            {seasons.map(season => (
              <FormControlLabel
                key={season.id}
                control={
                  <Checkbox
                    onChange={e => handleSeasonChange(e.target.checked, e.target.name)}
                    checked={selectedSeasons.includes(season.id)}
                    name={season.id}
                    sx={{py: 0.5}}
                  />
                }
                label={season.title}
              />
            ))}
          </FormGroup>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterMoment} locale={'de'}>
          <DesktopDatePicker
            label="Start-Datum"
            value={startDate}
            mask={'__.__.____'}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => (
              <TextField variant={"standard"} fullWidth className={classes.formControl} {...params} />
            )}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterMoment} locale={'de'}>
          <DesktopDatePicker
            label="End-Datum"
            value={endDate}
            mask={'__.__.____'}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => (
              <TextField variant={"standard"} fullWidth className={classes.formControl} {...params} />
            )}
          />
        </LocalizationProvider>

      </DialogContent>
      <DialogActions>
        <Button
          disabled={!startDate?.isValid}
          onClick={handleSubmit}
        >Speichern</Button>
      </DialogActions>
    </Dialog>
  );
}

AddDialog.propTypes = {}

export default AddDialog;
