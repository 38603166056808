import React from 'react';
import PropTypes from 'prop-types';

import BookingDateNavigation from "./BookingDateNavigation";
import moment from "moment";
import {useLocation} from 'wouter';
import {WEEKEND_URL_SEGMENT} from "../../config/constants";

const BookingDateNavigationProvider = ({ year, month, baseSegment = WEEKEND_URL_SEGMENT }) => {
  const [, setLocation] = useLocation();

  const handleBack = () => {
    const curr = moment(`${year}-${month}-01`, 'YYYY-MM-DD')
    setLocation(`/${baseSegment}/${curr.subtract(1, 'month').format('YYYY/M/')}`);
  }

  const handleNext = () => {
    const curr = moment(`${year}-${month}-01`, 'YYYY-MM-DD')
    setLocation(`/${baseSegment}/${curr.add(1, 'month').format('YYYY/M/')}`);
  }

  return (
    <BookingDateNavigation
      year={year}
      month={month}
      handleBack={handleBack}
      handleNext={handleNext}
    />
  );
}

BookingDateNavigationProvider.propTypes = {
  year: PropTypes.number,
  month: PropTypes.number,
}

export default BookingDateNavigationProvider;
