import {makeStyles} from '@mui/styles';
import {grey, red} from "@mui/material/colors";
import blue from "@mui/material/colors/blue";

/**
 * Material UI Styles for Layout feature
 */
export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  tableCell: {
    '&:hover': {
      background: 'red'
    }
  },
  dateNav: {
    margin: '15px 0 10px'
  },
  grid: {
    flexGrow: 1,
    // minWidth: '150px',
    flexWrap: 'nowrap'
  },
  gridSub: {
    flexGrow: 1,
    flexWrap: 'nowrap',
    position: 'relative',
  },
  listItem: {
    boxSizing: 'border-box',
    height: '25px',
    textAlign: 'center',
    borderRight: `1px solid ${grey["200"]}`,
    borderBottom: `1px solid ${grey["200"]}`,
  },
  listItemSub: {
    boxSizing: 'border-box',
    width: '100%',
    minWidth: '100px',
    height: '25px',
    textAlign: 'center',

    // borderRight: `1px solid ${grey["200"]}`,
  },
  title: {
    color: 'black',
    fontWeight: "bold",
    background: "rgba(255, 255, 255, 0.7)",
    padding: '0 2px'
  },
  available: {
    background: grey["200"],
    borderRight: `1px solid ${grey["300"]}`,
    borderBottom: `1px solid ${grey["300"]}`,
    '&:hover': {
      background: grey['300'],
      cursor: 'pointer'
    }
  },
  availableOdd: {
    background: grey["100"],
    borderRight: `1px solid ${grey["300"]}`,
    borderBottom: `1px solid ${grey["300"]}`,
    '&:hover': {
      background: grey['200'],
      cursor: 'pointer'
    }
  },
  availableContrast: {
    background: blue["300"],
    borderRight: `1px solid ${blue["400"]}`,
    borderBottom: `1px solid ${blue["400"]}`,
    '&:hover': {
      background: blue['400'],
      cursor: 'pointer'
    }
  },
  availableOddContrast: {
    background: blue["200"],
    borderRight: `1px solid ${blue["400"]}`,
    borderBottom: `1px solid ${blue["400"]}`,
    '&:hover': {
      background: blue['300'],
      cursor: 'pointer'
    }
  },
  full: {
    userSelect: 'none',
    background: red["500"],
    cursor: "grab"
  },
  fullOdd: {
    userSelect: 'none',
    background: red["400"],
    cursor: "grab"
  },
  fullTraining: {
    backgroundImage: `repeating-linear-gradient(-45deg, transparent 0%, transparent 12%, ${red["200"]} 12%, ${red["200"]} 20%, transparent 20%)`,
  },
  fullOddTraining: {
    backgroundImage: `repeating-linear-gradient(-45deg, transparent 0%, transparent 12%, ${red["500"]} 12%, ${red["500"]} 20%, transparent 20%)`,
  },
  hover: {
    background: red['500'],
    cursor: 'pointer'
  },
  hoverFree: {
    background: grey['500'],
    cursor: 'pointer'
  },
  topBorder: {
    borderTop: `1px solid ${red["700"]}`,
  },
  rightBorder: {
    borderRight: `1px solid ${red["700"]}`,
  },
  bottomBorder: {
    borderBottom: `1px solid ${red["700"]}`,
  },
  leftBorder: {
    borderLeft: `1px solid ${red["700"]}`,
  },
  primary: {
    fontSize: '12px'
  },
  secondary: {
    fontSize: '12px'
  },
  multiline: {
    marginTop: '0',
    marginBottom: '0',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  nopointer: {
    cursor: 'default !important'
  },
  virtual: {
    background: grey[300] + ' !important',
    transform: 'translate3d(-5px, -5px, 5px)',
    boxShadow: '3px 3px 4px #333',
    transition: 'transform 100ms ease-in-out',
    cursor: "grabbing"
  },
  dndFirst: {
    // background: 'yellow',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '8px',
    cursor: 'row-resize'
  },
  dndLast: {
    // background: 'blue',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '8px',
    cursor: 'row-resize'
  }
}));
