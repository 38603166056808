import React, {createContext, useContext, useState} from 'react';
import PropTypes from 'prop-types';

const TimeContext = createContext();

export const useTime = () => useContext(TimeContext)

const TimeContextProvider = ({ children: reactChildren }) => {
  // const classes = useStyles();
  const [currentTime, setCurrentTime] = useState(null)

  return (
    <TimeContext.Provider value={{currentTime, setCurrentTime}}>
      {reactChildren}
    </TimeContext.Provider>
  );
}

TimeContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default TimeContextProvider;
