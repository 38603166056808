import {createClient, dedupExchange, fetchExchange} from '@urql/core';
import cacheExchange from "./exchanges/cacheExchange";
import authExchange from './exchanges/authExchange'

import {GRAPHQL_ENDPOINT} from '../../config/config'

/**
 * URQL Client for silverstripe
 *
 * @returns {*}
 */
const GraphQLClientSilverstripe = createClient({
  url: GRAPHQL_ENDPOINT.url_public,
  exchanges: [
    dedupExchange,
    cacheExchange,
    authExchange,
    fetchExchange
  ],
});

export default GraphQLClientSilverstripe;
