import { Grid, ListItemButton } from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import { readPublicBuildings } from '../../lib/graphql/queries'
import { useQuery } from 'urql'
import IconButton from '@mui/material/IconButton'
import { ChevronRight } from '@mui/icons-material'
import ListItemIcon from '@mui/material/ListItemIcon'
import { useLocation } from 'wouter'
import { usePublicConfig } from '../../context/PublicContext'

const PublicOverview = () => {
  const [, setLocation] = useLocation()
  const { rooms } = usePublicConfig()

  console.log(rooms)

  return (
    <>
      <Grid item xs={12}>
        <List>
          {(rooms || []).map(room => (
            <ListItemButton onClick={() => setLocation(`/${room.id}/`)}>
              <ListItemText
                primary={room.title}
                secondary={room.Building.title}
              />
              <ListItemIcon>
                <ChevronRight />
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Grid>
    </>
  )
}

export default PublicOverview;
